<template>
  <div class="deskeeper-map">
    <!-- 筛选 -->
    <!-- <div class="deskeeper-map-filter">
      <div>楼层</div>
      <div>区域</div>
      <div>开始时间</div>
      <div>结束时间</div>
    </div> -->
    <iot-form
      v-if="formVisible"
      class="deskeeper-map-filter"
      :form-items="formItems"
      :form-data="formData"
      @btn-click="btnEvent"
    >
      <template slot="floor-name">
        <div class="filter-time filter-select">{{ formData.floorName }}</div>
      </template>
      <template slot="area-name">
        <div
          class="filter-time filter-select"
          :style="formData.areaName ? {} : { color: '#999' }"
        >
          {{ formData.areaName || "区域" }}
        </div>
      </template>
      <template slot="startTime">
        <div class="filter-time">
          <div>{{ formData.startTimeText.split(" ")[0] }}</div>
          <div>{{ formData.startTimeText.split(" ")[1] }}</div>
        </div>
      </template>
      <template slot="endTime">
        <div class="filter-time">
          <div>{{ formData.endTimeText.split(" ")[0] }}</div>
          <div>{{ formData.endTimeText.split(" ")[1] }}</div>
        </div>
      </template>
    </iot-form>
    <!-- 地图 -->
    <div ref="fengMap" class="deskeeper-map-fengMap"></div>
    <!-- 图例 -->
    <div class="deskeeper-map-type" v-if="formVisible">
      <template v-for="(x, i) in deskeeperStatusArr">
        <div :key="i">
          <span :style="{ backgroundColor: x.color }"></span>{{ x.label }}
        </div>
      </template>
    </div>
    <!-- 选择工位信息 -->
    <div class="deskeeper-map-bom" v-if="deskInfos.length > 0">
      <van-swipe
        class="deskeeper-map-swipe"
        :show-indicators="deskInfos.length > 1"
        indicator-color="#07c160"
      >
        <van-swipe-item v-for="x in deskInfos" :key="x.id">
          <div class="swipe-info">
            <div class="info-content">
              <div class="desk-icon"></div>
              <div class="desk-info">
                <p>
                  您选择的工位：{{ x.locationAddress.split("-")[3] }}-{{
                    x.name
                  }}
                </p>
                <p><span @click="lookFacilities(x.id)">查看工位设施</span></p>
              </div>
            </div>
            <div class="info-time"></div>
          </div>
          <div class="desk-info-delete" @click="delStation(x.id)">
            <van-icon name="cross" size="20"></van-icon>
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="deskeeper-map-btn">
        <van-button size="small" @click="cancelStation">取消</van-button>
        <van-button size="small" type="primary" @click="sureReserve"
          >确定预约</van-button
        >
      </div>
    </div>
    <!-- 弹出窗 -->
    <van-popup v-model="popVisible" :closeable="true">
      <div class="facilities-view">
        <template v-for="(x, i) in stationFacilities">
          <div class="facilities-view-item" :key="i">
            <span>{{ x.assetName }}</span>
            <span>{{ x.assetModel || "" }}</span>
          </div>
        </template>
        <div v-if="stationFacilities.length === 0" class="no-data">
          暂未配置设施
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { setCookie } from "@/utils/cookie";
import {
  GetLocation,
  GetMapInfo,
  StationDetails,
  StationReserve,
  StationAssets,
  BehaviorBury,
} from "@/api/deskeeper";

import startImg from "./images/start.png";
import endImg from "./images/end.png";

document.title = "预定工位";

export default {
  data() {
    const typeStatus = [
      {
        val: 3,
        label: "已占用",
        color: "#F56C6C",
      },
      {
        val: 1,
        label: "可预约",
        color: "#67C23A",
      },
      {
        val: 2,
        label: "已预约",
        color: "#E6A23C",
      },
      {
        val: -1,
        label: "不可预约",
        color: "#F5F7FA",
      },
    ];
    let typeStatusOb = {};
    typeStatus.forEach((item) => {
      typeStatusOb[item.val] = item;
    });
    const params = this.$route.query;
    setCookie("token", params.token);
    return {
      requestWait: false,
      buildingId: params.locationId,
      initFloorId: "",
      stationId: params.stationId || "",
      isNav: params.nav || "",
      formVisible: false,
      popVisible: false,
      mapOptions: {
        // 应用名称
        appName: "test1",
        // 秘钥
        key: "7221eb8151abdf30448c490c82c40f49",
        // 初始化比例
        defaultMapScale: 300,
        // 背景颜色
        defaultBackgroundColor: "#ffffff",
        // 旋转角度
        defaultControlsPose: 0,
        // 主题
        defaultThemeName: "",
        // 初始显示楼层ID数组
        defaultVisibleGroups: [],
        // 初始聚焦楼层
        defaultFocusGroup: "",
        // 单击模型是否高亮
        modelSelectedEffect: false,
        // 2维 / 3维
        // defaultViewMode: fengmap.FMViewMode.MODE_2D
      },
      fmapID: "",
      mapOb: null,
      locationMarker: null,
      searchAnalyser: null,
      btnFloorControl: null,
      //定义markert图层数组
      layers: [],
      //起终点坐标
      coords: [],
      // 导航请求定位点定时器
      naviInt: null,
      //  导航对象
      navi: null,
      // 导航是否结束
      naviStoped: false,
      // 导航开关
      naviSwitch: true,
      // 导航过程中地图缩放比例
      naviScaleLevel: 22,
      // 距离终点的最大距离，结束导航  单位：m
      maxEndDistance: 5,
      // 路径便宜的最大距离
      maxOffsetDis: 15,
      // 路径偏移的最小距离，在最小距离以内坐标点自动约束到路径线上
      minOffsetDis: 3,
      // 路径线真实坐标点数据
      coordsData: [],
      // 定位点下标
      coordIndex: 0,
      //
      naviAnalyser: null,
      startPointX: "",
      startPointY: "",
      deskInfos: [],
      formData: {
        floorId: "",
        floorName: "",
        areaId: "",
        areaName: "",
        startTime: "",
        startTimeText: "",
        endTime: "",
        endTimeText: "",
      },
      formItems: [
        {
          type: "picker",
          label: "",
          placeholder: "楼层",
          prop: "floorName",
          otherSlot: "floor-name",
          class: "filter-time-field",
          clickable: true,
          selectConfig: {
            labelName: "floorName",
            valName: "floorId",
            valueChangeName: "floorChange",
            options: [],
          },
        },
        {
          type: "picker",
          label: "",
          placeholder: "区域",
          prop: "areaName",
          otherSlot: "area-name",
          class: "filter-time-field",
          clickable: true,
          selectConfig: {
            labelName: "areaName",
            valName: "areaId",
            valueChangeName: "areaChange",
            options: [],
          },
        },
        {
          type: "time",
          label: "",
          placeholder: "开始时间",
          prop: "startTimeText",
          clickable: true,
          otherSlot: "startTime",
          class: "filter-time-field",
          selectConfig: {
            pickerType: "datetime",
            valueFormat: "YYYY-MM-DD HH:mm",
            minDate: new Date(),
            currentDate: ''
          },
          hidden: false,
        },
        {
          type: "time",
          label: "",
          placeholder: "结束时间",
          prop: "endTimeText",
          otherSlot: "endTime",
          class: "filter-time-field",
          clickable: true,
          selectConfig: {
            pickerType: "datetime",
            valueFormat: "YYYY-MM-DD HH:mm",
            minDate: new Date(),
            currentDate: '',
          },
          hidden: false,
        },
      ],
      stationsInfo: {},
      deskeeperTargets: [],
      deskeeperStatus: typeStatusOb,
      deskeeperStatusArr: typeStatus,
      selectStationOb: {},
      stationDetailsOb: {},
      stationFacilities: [],
      selectColor: "#cc66ff",
    };
  },
  computed: {
    floorId() {
      return this.formData.floorId;
    },
    startTime() {
      return this.formData.startTime;
    },
    startTimeText() {
      return this.formData.startTimeText;
    },
    endTime() {
      return this.formData.endTime;
    },
    endTimeText() {
      return this.formData.endTimeText;
    },
  },
  watch: {
    fmapID(val) {
      // 初始化地图
      this.mapInit();
    },
    buildingId() {
      this.getLocationData();
    },
    floorId(val) {
      if (val) {
        this.formItems[0].selectConfig.options.forEach((item) => {
          if (item.floorId === val) {
            this.formItems[1].selectConfig.options = item.areas;
          }
        });
        this.getDeskeeperInfo();
      }
      this.formData.areaId = "";
      if (val != this.initFloorId) {
        this.initFloorId = "";
      }
      if (!this.initFloorId) {
        this.formData.areaName = "";
      }
    },
    startTime(val) {
      this.formData.startTimeText = dayjs(new Date(dayjs(val))).format(
        "YYYY-MM-DD HH:mm"
      );
      if (val > this.formData.endTime) {
        this.formData.endTime = new Date(dayjs(val)).getTime() + 15 * 60 * 1000;
        return;
      }
      
      if (this.mapOb) {
        this.getDeskeeperInfo();
        this.cancelStation();
      }
    },
    startTimeText(val) {
      this.formData.startTime = new Date(dayjs(val)).getTime();
    },
    endTime(val) {
      if (val < this.formData.startTime) {
        this.formData.endTime =
          new Date(dayjs(this.formData.startTime)).getTime() + 15 * 60 * 1000;
        return;
      }
      this.formData.endTimeText = dayjs(new Date(dayjs(val))).format(
        "YYYY-MM-DD HH:mm"
      );
      if (this.mapOb) {
        this.getDeskeeperInfo();
        this.cancelStation();
      }
    },
    endTimeText(val) {
      this.formData.endTime = new Date(dayjs(val)).getTime();
    },
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * @description 初始化
     */
    init() {
      let startH = dayjs(new Date()).format("HH");
      let startM = dayjs(new Date()).format("mm");
      if (startM % 5 > 0) {
        startM = startM - (startM % 5) + 5;
        startM = Number(startM) < 10 ? "0" + startM : startM;
        if (Number(startM) === 60) {
          startM = "00";
          startH = Number(startH) + 1;
        }
      }
      this.formData.startTimeText =
        dayjs(new Date()).format("YYYY-MM-DD") + " " + startH + ":" + startM;
      this.formData.endTimeText = dayjs(
        new Date(dayjs(this.formData.startTimeText)).getTime() + 15 * 60 * 1000
      ).format("YYYY-MM-DD HH:mm");
      this.formItems[2].selectConfig.currentDate = new Date(this.formData.startTimeText)
      this.formItems[3].selectConfig.currentDate = new Date(this.formData.endTimeText)

      if (this.buildingId) {
        // 获取楼层及分区数据
        this.getLocationData();
      }
      if (this.stationId) {
        this.getDeskeeperInfo();
      }
    },
    /**
     * @description 初始化地图
     */
    mapInit() {
      if (this.mapOb) return;
      this.$toast.loading({
        message: "地图数据加载中...",
        forbidClick: true,
      });
      // 地图容器
      this.mapOptions.container = this.$refs.fengMap;
      this.mapOb = new fengmap.FMMap(this.mapOptions);
      this.mapOb.openMapById(this.fmapID);

      this.mapOb.pickFilterFunction = this.pickFilterFun;
      // 地图加载完成事件
      this.mapOb.on("loadComplete", this.mapLoadComplete);
      // 点击事件
      this.mapOb.on("mapClickNode", this.mapClickNode);
    },
    /**
     * @description 按钮事件
     * @param { String } eventName 事件名称
     */
    btnEvent(eventName, info) {
      this["_" + eventName](info);
    },
    /**
     * @description 地图加载完成
     */
    mapLoadComplete() {
      console.log("loadComplete");
      this.$toast.clear();
      let _this = this;
      this.searchAnalyser = new fengmap.FMSearchAnalyser(this.mapOb);
      let request = new fengmap.FMSearchRequest();
      this.searchAnalyser.query(request, function (result) {
        result.forEach((item) => {
          if (item.typeID === 200501) _this.deskeeperTargets.push(item);
        });
      });
      /**
       * fengmap.FMNaviAnalyser 是可分析最短路径、最快路径并返回分析结果的路径类
       * https://developer.fengmap.com/docs/js/v2.7.1/fengmap.FMNaviAnalyser.html
       **/
      this.naviAnalyser = new fengmap.FMNaviAnalyser(this.mapOb);

      if (this.isNav && this.stationId) {
        let coord = {
          x: this.startPointX,
          y: this.startPointY,
          groupID: this.mapOptions.defaultFocusGroup,
        };
        this.coords[0] = coord;
        // 添加初始定位点图标
        this.addMarker(coord, "start");
      }
      // 工位状态
      this.setDeskeeperModel();
    },
    /**
     * @description 过滤是否可触发点击事件mapClickNode方法的地图元素，返回true为可触发
     */
    pickFilterFun(event) {
      if (this.isNav && this.stationId) {
        return false;
      }
      if (
        event.typeID === 200501 &&
        this.stationsInfo[event.data.fid] &&
        this.stationsInfo[event.data.fid].reserveStatus === 1
      ) {
        return true;
      }
      return false;
    },
    /**
     * 添加起点终点marker
     * coord: 模型中心点坐标
     * type: start-起点坐标， end-终点坐标
     * */
    addMarker(coord, type) {
      //获取目标点层
      var group = this.mapOb.getFMGroup(coord.groupID);
      //创建marker，返回当前层中第一个imageMarkerLayer,如果没有，则自动创建
      var layer = group.getOrCreateLayer("imageMarker");
      //判断该楼层layer是否存在，清除marker时需要将所有楼层marker都清除
      let isExistLayer = this.layers.some(function (item, index, array) {
        return item.groupID === coord.groupID;
      });
      if (!isExistLayer) {
        this.layers.push(layer);
      }
      var markerUrl = "";
      if (type === "start") {
        markerUrl = startImg;
      } else {
        markerUrl = endImg;
      }
      //图标标注对象，默认位置为该楼层中心点
      var im = new fengmap.FMImageMarker({
        x: coord.x,
        y: coord.y,
        //设置图片路径
        url: markerUrl,
        //设置图片显示尺寸
        size: 32,
        //marker标注高度
        height: 2,
      });
      //添加imageMarker
      layer.addMarker(im);
    },
    /**
     * 画导航线
     * https://developer.fengmap.com/docs/js/v2.7.1/fengmap.FMNaviAnalyser.html
     * */
    drawNaviLine() {
      //根据已加载的fengmap.FMMap导航分析，判断路径规划是否成功
      var analyzeNaviResult = this.naviAnalyser.analyzeNavi(
        this.coords[0].groupID,
        this.coords[0],
        this.coords[1].groupID,
        this.coords[1],
        fengmap.FMNaviMode.MODULE_SHORTEST
      );
      console.log(analyzeNaviResult);
      if (fengmap.FMRouteCalcuResult.ROUTE_SUCCESS != analyzeNaviResult) {
        return;
      }
      //获取路径分析结果对象，所有路线集合
      var results = this.naviAnalyser.getNaviResults();
      //初始化线图层
      var line = new fengmap.FMLineMarker();
      for (var i = 0; i < results.length; i++) {
        var result = results[i];
        //楼层id
        var gid = result.groupId;
        //路径线点集合
        var points = result.getPointList();
        var points3d = [];
        points.forEach(function (point) {
          points3d.push({
            //x坐标点
            x: point.x,
            //y坐标点
            y: point.y,
            //线标注高度
            z: 1,
          });
        });
        /**
         * fengmap.FMSegment点集，一个点集代表一条折线
         * https://developer.fengmap.com/docs/js/v2.7.1/fengmap.FMSegment.html
         * */
        var seg = new fengmap.FMSegment();
        seg.groupId = gid;
        seg.points = points3d;
        line.addSegment(seg);
      }
      //配置线型、线宽、透明度等
      var lineStyle = {
        //设置线的宽度
        lineWidth: 6,
        //设置线的透明度
        alpha: 0.8,
        //设置线的类型为导航线
        lineType: fengmap.FMLineType.FMARROW,
        //设置线动画,false为动画
        noAnimate: true,
      };
      //画线
      this.mapOb.drawLineMark(line, lineStyle);
    },
    /**
     * @description 加载楼层切换控件
     */
    loadBtnFloorCtrl() {
      //楼层控制控件配置参数
      var btnFloorCtlOpt = {
        //默认在右下角
        position: fengmap.FMControlPosition.RIGHT_TOP,
        //初始楼层按钮显示个数配置。默认显示5层,其他的隐藏，可滚动查看
        showBtnCount: 6,
        //初始是否是多层显示，默认单层显示
        allLayer: false,
        //位置x,y的偏移量
        offset: {
          x: -20,
          y: 50,
        },
      };
      //不带单/双层楼层控制按钮,初始时只有1个按钮,点击后可弹出其他楼层按钮
      this.btnFloorControl = new fengmap.FMButtonGroupsControl(
        this.mapOb,
        btnFloorCtlOpt
      );
      //楼层切换
      this.btnFloorControl.onChange(function (groups, allLayer) {
        //groups 表示当前要切换的楼层ID数组,
        //allLayer表示当前楼层是单层状态还是多层状态。
        console.log("当前切换楼层：" + groups);
      });
      //切换楼层,changeFocusGroup(目标层groupID,是否多层状态)
      // this.btnFloorControl.changeFocusGroup(2, true);
      //默认是否展开楼层列表，true为展开，false为不展开
      this.btnFloorControl.expand = true;
      //楼层控件是否可点击，默认为true
      this.btnFloorControl.enableExpand = true;
    },
    /**
     * @description 工位点击事件
     */
    mapClickNode(event) {
      if (!event.target) return;
      let info = event.target;
      console.log(this.stationsInfo[info.FID]);
      if (this.selectStationOb[this.stationsInfo[info.FID].id]) {
        event.target.setColor(this.deskeeperStatus["1"].color);
        this.selectStationOb[this.stationsInfo[info.FID].id] = false;
        // 删除工位预订
        this.delStation(this.stationsInfo[info.FID].id);
      } else {
        event.target.setColor(this.selectColor);
        this.selectStationOb[this.stationsInfo[info.FID].id] = true;
        // 添加工位预定
        this.addStation(this.stationsInfo[info.FID].id);
      }
    },
    /**
     * @description 获取位置信息
     */
    getLocationData() {
      GetLocation({
        buildingId: this.buildingId,
      }).then((res) => {
        if (this.stationId) {
          res.forEach((item) => {
            if (Number(item.floorId) === Number(this.initFloorId)) {
              this.formData.floorId = item.floorId;
              this.formData.floorName = item.floorName;
              // const curArea = item.areas.filter(
              //   (list) => list.areaId === this.areaId
              // );
              // this.formData.areaName = curArea[0].areaName;
              // this.formData.areaId = curArea[0].areas[0].areaId;
            }
          });
        } else {
          this.formData.floorId = res[0].floorId;
          this.formData.floorName = res[0].floorName;
          // this.formData.areaId = res[0].areas[0].areaId;
        }
        this.formItems[0].selectConfig.options = res;
        this.formVisible = true;
      });
    },
    /**
     * @description 获取工位信息
     */
    getDeskeeperInfo() {
      GetMapInfo({
        locationId: this.formData.floorId,
        startTime: this.formData.startTime,
        endTime: this.formData.endTime,
        stationId: this.stationId,
      }).then((res) => {
        if (res.stations && res.stations.length > 0) {
          res.stations.forEach((item) => {
            this.stationsInfo[item.resourceCode] = item;
          });
        }
        if (this.mapOb) {
          this.setDeskeeperModel();
        } else {
          this.fmapID = res.mapResourceCode;
          this.mapOptions.defaultFocusGroup = res.defaultFocusGroup;
          this.mapOptions.defaultVisibleGroups = res.fmapFloorList;
          this.mapOptions.defaultThemeName = res.themeKey;
          this.startPointX = res.startPointX;
          this.startPointY = res.startPointY;
          this.areaId = res.areaId;
        }

        if (this.stationId && !this.isNav) {
          this.buildingId = res.buildingId;
          this.initFloorId = res.floorId;
        }
      });
    },
    /**
     * @description 工位状态
     */
    setDeskeeperModel() {
      if (this.isNav && this.stationId) {
        this.deskeeperTargets.forEach((item) => {
          let model = item.target;
          if (this.stationsInfo[model.FID]) {
            //修改颜色
            if (model.setColor) {
              if (Number(this.stationId) === this.stationsInfo[model.FID].id) {
                model.setColor(this.deskeeperStatus["1"].color);
                let coord = {
                  x: this.stationsInfo[model.FID].x,
                  y: this.stationsInfo[model.FID].y,
                  groupID: this.mapOptions.defaultFocusGroup,
                };
                this.coords[1] = coord;
                // 添加结束点
                this.addMarker(coord, "end");
                // 画导航线
                this.drawNaviLine();
              } else {
                model.setColor(this.deskeeperStatus["-1"].color);
              }
            }
          }
        });
        return;
      }
      this.deskeeperTargets.forEach((item) => {
        let model = item.target;
        if (this.stationsInfo[model.FID]) {
          //修改颜色
          if (model.setColor) {
            if (
              this.stationId &&
              !this.isNav &&
              Number(this.stationId) === Number(this.stationsInfo[model.FID].id)
            ) {
              model.setColor(this.selectColor);
            } else {
              model.setColor(
                this.deskeeperStatus[this.stationsInfo[model.FID].reserveStatus]
                  .color
              );
            }
          }
        }
      });
      if (this.stationId && !this.isNav) {
        if (this.deskInfos.length === 0) {
          this.addStation(this.stationId);
          this.selectStationOb[this.stationId] = true;
        }
      }
    },
    /**
     * @description 添加工位预定
     */
    addStation(id) {
      if (this.stationDetailsOb[id]) {
        this.deskInfos.push(this.stationDetailsOb[id]);
        return;
      }
      StationDetails({
        stationId: id,
      }).then((res) => {
        if (!this.stationDetailsOb[id]) {
          this.deskInfos.push(res);
        }
        this.stationDetailsOb[id] = res;
        console.log("deskInfos ==> ", this.deskInfos);
      });
    },
    /**
     * @description 删除工位预订
     */
    delStation(id) {
      if (this.selectStationOb[id]) {
        this.deskeeperTargets.forEach((item) => {
          let model = item.target;
          if (model.FID === this.stationDetailsOb[id].resourceCode) {
            //修改颜色
            if (model.setColor)
              model.setColor(
                this.deskeeperStatus[
                  String(this.stationsInfo[model.FID].reserveStatus)
                ].color
              );
          }
        });
        this.selectStationOb[id] = false;
      }
      for (var i = 0, len = this.deskInfos.length; i < len; i++) {
        if (this.deskInfos[i].id === id) {
          this.deskInfos.splice(i, 1);
          return;
        }
      }
    },
    /**
     * @description 查看工位设施
     */
    lookFacilities(id) {
      StationAssets({
        stationId: id,
      }).then((res) => {
        this.stationFacilities = res;
        this.popVisible = true;
      });
    },
    /**
     * @description 取消
     */
    cancelStation() {
      const arr = JSON.parse(JSON.stringify(this.deskInfos));
      arr.forEach((item) => {
        this.delStation(item.id);
      });
    },
    /**
     * @description 确定预约
     */
    sureReserve() {
      if (this.requestWait) return;
      this.requestWait = true;
      let orders = [];
      if ((this.formData.endTime - this.formData.startTime) === 0) {
        this.$toast("预约结束时间等于开始时间！");
        this.requestWait = false;
        return false
      }
      this.deskInfos.forEach((item) => {
        orders.push({
          startTimestramp: this.formData.startTime,
          endTimestramp: this.formData.endTime,
          stationId: item.id,
        });
      });
      this.$toast.loading({
        message: "工位预定中...",
        forbidClick: true,
      });
      StationReserve({
        reserveOrders: orders,
      })
        .then((res) => {
          console.log(res);
          this.$toast.success("预定成功");
          jWeixin.miniProgram.reLaunch({
            url:
              "/pages/deskeeper/reserve-detail/index?reserveOrder=" +
              res.reserveOrder,
          });
        })
        .finally(() => {
          this.requestWait = false;
          BehaviorBury({
            source: "APPLET",
          });
        });
    },
    /**
     * @description 楼层选择
     */
    _floorChange(info) {
      this.formData.floorId = info.floorId;
      if (this.mapOb) {
        // 取消已预定
        this.cancelStation();
        // 展示单楼层
        this.mapOb.visibleGroupIDs = [info.resourceCode];
        //设置地图聚焦楼层
        this.mapOb.focusGroupID = Number(info.resourceCode);
      }
    },
    /**
     * @description 区域选择
     */
    _areaChange(info) {
      this.formData.areaId = info.areaId;
      // 移动地图
      this.mapOb.moveTo({
        x: info.x,
        y: info.y,
        time: 1,
      });
    },
  },
};
</script>

<style lang="scss">
.deskeeper-map {
  width: 100%;
  height: 100%;
  position: absolute;
  .deskeeper-map-fengMap {
    width: 100%;
    height: 100%;
  }
  .deskeeper-map-filter {
    width: 100%;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    .iot-form-main {
      width: 100%;
      height: 100%;
      padding: 0;
      .content {
        width: 100%;
        height: 100%;
        display: flex;
        .van-cell {
          padding: 10px;
        }
        .iot-form-item input {
          text-align: center;
        }
        .iot-form-item.item-arrow-up .van-field,
        .iot-form-item.item-arrow-down .van-field {
          background-size: 15px auto;
        }
      }
    }
    .iot-form-btn {
      display: none;
    }
    .filter-time {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: #fff;
      line-height: 20px;
      text-align: center;
      padding-top: 5px;
      font-size: 14px;
      .iot-form-item input {
        font-size: 13px;
      }
      &.filter-select {
        padding-top: 12px;
        padding-right: 10px;
        background-image: url("./images/arrow.png");
        background-repeat: no-repeat;
        background-position: top 15px right 5px;
        background-size: 15px auto;
      }
    }
    .filter-time-field {
      .van-field__control {
        opacity: 0;
      }
    }
  }
  .deskeeper-map-bom {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    .deskeeper-map-swipe {
      width: 100%;
      .van-swipe-item {
        padding: 0 10px;
      }
      .desk-info-delete {
        position: absolute;
        top: 0px;
        right: 10px;
        padding: 5px 7px;
      }
    }
    .swipe-info {
      border-radius: 10px;
      border: 1px solid #e7e7e7;
      padding: 10px 0;
      background-color: #fff;
      .info-content {
        display: flex;
        .desk-icon {
          width: 50px;
          height: 50px;
          background-image: url("./images/desk-active.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 30px auto;
        }
        .desk-info {
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            color: #5a62ce;
          }
        }
      }
      .info-time {
      }
    }
    .deskeeper-map-btn {
      width: 100%;
      padding: 10px;
      text-align: center;
      & > button:not(:first-child) {
        margin-left: 100px;
      }
    }
  }
  .deskeeper-map-type {
    line-height: 24px;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    margin: 0 0 10px 10px;
    // width: 110px;
    background-color: #fff;
    padding: 10px;
    position: fixed;
    left: 0;
    bottom: 150px;
    z-index: 10;
    & > div > span {
      display: inline-block;
      width: 15px;
      height: 15px;
      float: left;
      margin: 5px 10px 0 0;
    }
  }
  .facilities-view {
    width: 320px;
    border-radius: 10px;
    min-height: 200px;
    padding: 40px 20px 20px;
    & > div {
      line-height: 20px;
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
    }
    .no-data {
      color: #999;
      text-align: center;
      padding-top: 50px;
      justify-content: center;
    }
  }
}
</style>
