import request from '@/utils/request'

let baseURL = '/deskeeper'

// 获取建筑下楼层及分区数据
export function GetLocation(data) {
    return request({
        baseURL: baseURL,
        url: '/wechat/location/building-child',
        method: 'get',
        params: data
    })
}

// 获取工位信息
export function GetMapInfo(data) {
    return request({
        baseURL: baseURL,
        url: '/wechat/station/map',
        method: 'get',
        params: data
    })
}

// 工位详情
export function StationDetails(data) {
    return request({
        baseURL: baseURL,
        url: '/wechat/station/details',
        method: 'get',
        params: data
    })
} 

// 工位资产详情
export function StationAssets(data) {
    return request({
        baseURL: baseURL,
        url: '/wechat/station/asset',
        method: 'get',
        params: data
    })
} 

// 工位预定
export function StationReserve(data) {
    return request({
        baseURL: baseURL,
        url: '/wechat/book/confirm',
        method: 'post',
        data
    })
} 

// 行为数据-埋点
export function BehaviorBury(data) {
    return request({
        baseURL: baseURL,
        url: '/station/statistics/behavior/bury',
        method: 'post',
        data
    })
} 